import React , { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from "reactstrap";
import classnames from "classnames";
import Carousel from 'react-bootstrap/Carousel';
import API_URL from  './../../../ApiUrl';
import Img1 from '../../../assets/img/Banner-01.png';
import axios from 'axios';

class LandingPageHeader extends Component {

  state = {
    dataGuru: [],
    dataGuruFilter:[],
  }

  componentDidMount() {
    this.getDataGuru();
  }

  getDataGuru() {
    const url = API_URL.superStudentApplication+'/english-course/list-teacher';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ 
        dataGuru: data 
      }, () => {
        const filterGuru = this.state.dataGuru.filter(guru => {
          return guru.status == 'Y';
        });

        this.setState({dataGuruFilter:filterGuru})
      })
    })
  }

  render() {
    return (
      <>
        <Carousel style={{height: '100%'}}>
          {
            this.state.dataGuruFilter.length > 0 ?
            this.state.dataGuruFilter.map(guru => (
              <Carousel.Item>
                <img src={guru.foto} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '190px', width: '75%'}} />
              </Carousel.Item>
            ))
            :
            ''
          }
        </Carousel>
      </>
    );
  }
}

export default LandingPageHeader;
