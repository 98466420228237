const url = "http://103.41.205.45:1949";
const master = "http://103.41.205.45:1936";
const membership = "http://103.41.205.45:1935";
const isMakassarKey = 'WUhtZP40YhPa39Fg5zdOubgE4Zjb8bJN';
const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
const membershipKey = 'FYzev0v4c2wLHoewHjLQHps1WxnhLgVB';
const superStudent = 'https://superstudent.niecindonesia.com';
const superStudentApplication = 'https://superstudent.niecindonesia.com/api/v1/application';
const ambassadorKey = 'Y3G4gG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV';

// const superStudent = 'http://103.41.205.87:80';
// const superStudentApplication = 'http://103.41.205.87:80/api/v1/application';


const API_URL = {
    url,
    master,
    membership,
    isMakassarKey,
    masterKey,
    membershipKey,
    superStudent,
    superStudentApplication,
    ambassadorKey
}

export default API_URL;

// export default api_url;