import React, { Component } from "react";
import axios from 'axios';
import API_URL from './../../../ApiUrl';
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { LandingPageHeader, ProfilePageHeader, ExamplesNavbar, DemoFooter, Register } from './../../../components/molecules/';



class LandingPage extends Component {
  state = {
    nama_lengkap: "",
    no_telp: "",
    email: "",
    id_propinsi: "",
    id_kabkota: "",
    message: "Error !",
    error: {},
    showSwal: false,
    formAlert: { display: 'none' },
    formAlertClassName: 'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    dataPropinsi: [],
    dataKabkota: [],
    dataKabkotafilter: [],
    aktifKonten: [],
    checkemail: "",
    pesanEmailError: "",
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    //nama
    if (!this.state.nama_lengkap) {
      formIsValid = false;
      errors.nama_lengkap = "Nama wajib diisi";
      this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if (!this.state.no_telp) {
      formIsValid = false;
      errors.no_telp = "No telepon wajib diisi";
      this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if (!this.state.email) {
      formIsValid = false;
      errors.email = "Email wajib diisi";
      this.inputEmail.style = this.state.inputBorder;
    }
    if (typeof this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors.email = "Email tidak valid";
        this.inputEmail.style = this.state.inputBorder;
      }
    }
    if (this.state.checkemail === "Tolak") {
      formIsValid = false;
      errors.email = this.state.pesanEmailError;
      this.inputEmail.style = this.state.inputBorder;
    }
    //pripinsi
    if (!this.state.id_propinsi) {
      formIsValid = false;
      errors.id_propinsi = "Provinsi wajib diisi";
      this.inputPropinsi.style = this.state.inputBorder;
    }
    //kabkota
    if (!this.state.id_kabkota) {
      formIsValid = false;
      errors.id_kabkota = "Kabupaten / Kota wajib diisi";
      this.inputKabKota.style = this.state.inputBorder;
    }
    this.setState({ error: errors });
    return formIsValid;
  }

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value })
    this.checkEmail();
  }

  checkOrder() {
    var formData = new FormData();
    formData.append('id_member', this.props.dataUser.id_member);
    axios.post(API_URL.url + '/v2/member/checkorder', formData, { headers: { 'Authorization': `Bearer ${API_URL.membershipKey}` } }).then((reply) => {
      this.setState(prevState => {
        let code_order = Object.assign({}, prevState.code_order);
        code_order = reply.data;
        return { code_order };
      })
    })
  }

  UpdateStatus() {
    if (this.state.transaction_status != 'settlement') {
      console.log('!= settlement')
    } else {
      console.log('=== settlement')
      this.setState({ showSwalSuccess: true, SwalMessageSuccess: 'Transaksi telah berhasil, Silahkan Logout dan Login kembali' })
    }
  }

  registrationSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.sendData();
    } else {
      this.setState({
        formAlert: { display: 'unset' },
        formAlertClassName: 'alert alert-warning',
        message: "Error !"
      })
    }
  }

  sendData() {
    this.setState({
      message: "Proses simpan, mohon menunggu beberapa saat.",
      formAlert: { display: 'block' },
      formAlertClassName: 'alert alert-warning',
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('nama_lengkap', this.state.nama_lengkap);
    formData.append('no_wa', this.state.no_telp);
    formData.append('email', this.state.email);
    formData.append('id_propinsi', this.state.id_propinsi);
    formData.append('id_kabkota', this.state.id_kabkota);

    axios({
      method: 'post',
      url: API_URL.url + '/peserta/register',
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      //console.log(reply)
      if (reply.data[0].id_register !== undefined) {
        this.setState({ showSwal: true })
      } else {
        // alert(reply.data);
        this.setState({ btnDisabled: false })
        // this.hideAlert();
        alert("Pendaftaran Berhasil!")
        window.location.reload();
      }
    }).catch(function (reply) {
      console.log(reply)
    });
  }

  onBlurNamaLengkap(e) {
    e.preventDefault()
    if (this.state.nama_lengkap.length > 2) {
      this.inputNamaLengkap.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.nama_lengkap = '';
        return { error };
      })
    }
  }
  onBlurNoTelp(e) {
    e.preventDefault()
    if (this.state.no_telp.length > 0) {
      this.inputNoTelp.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.no_telp = '';
        return { error };
      })
    } else {
      this.inputNoTelp.style = this.state.inputBorder;
      this.setState(prevState => {
        let errors = Object.assign({}, prevState.error);
        errors.no_telp = 'No. Telp Tidak boleh kosong';
        this.setState({ error: errors });
      })
    }
  }
  onBlurEmail(e) {
    e.preventDefault()
    this.checkEmail();
    if (this.state.email.length > 5) {
      this.inputEmail.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.email = '';
        return { error };
      })
    }
  }
  onBlurPropinsi(e) {
    e.preventDefault()
    if (this.state.id_propinsi.length > 2) {
      this.inputPropinsi.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.id_propinsi = '';
        return { error };
      })
    }
  }
  onBlurKabkota(e) {
    e.preventDefault()
    if (this.state.id_kabkota.length > 2) {
      this.inputKabKota.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.id_kabkota = '';
        return { error };
      })
    }
  }

  getDataPropinsi() {
    const url = API_URL.master + '/propinsi';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.masterKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ dataPropinsi: data })
      })
  }

  getDataKabkota() {
    const url = API_URL.master + '/kabkota';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.masterKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ dataKabkota: data })
      })
  }

  setPropinsi(e) {
    e.preventDefault();
    this.setState({
      id_propinsi: e.target.value
    })

    const data = Object.values(this.state.dataKabkota);
    const id = e.target.value;
    const filterData = data.filter(dataFilter => {
      return dataFilter.id_propinsi == id;
    });
    this.setState({ dataKabkotafilter: filterData })
    console.log(filterData)
  }

  setKabkota(e) {
    e.preventDefault();
    this.setState({
      id_kabkota: e.target.value
    })
    console.log(this.state.id_kabkota)
  }

  radioChangeHandler = (event) => {
    this.setState({
      konsultan: event.target.value
    })
  }
  componentDidMount() {
    this.getKontenAktif();
    this.getDataPropinsi();
    this.getDataKabkota();
  }

  checkEmail() {
    const url = API_URL.url + '/peserta/cekmail';

    var formData = new FormData();
    formData.append('email', this.state.email);
    axios({
      method: 'post',
      url: url,
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
      })
  }

  getKontenAktif() {
    const url = API_URL.url + '/konten/aktifkonten';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ aktifKonten: data[0] })
      })
  }

  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputNamaSekolahlah.value = "";
    this.inputKelasSemester.value = "";
    this.inputPassword.value = "";
    this.selectPropinsi.value = "";
    this.selectKabkota.value = "";
    this.inputRePassword.value = "";
    this.inputKodePromo.value = "";
  }

  render() {

    return (
      <>
        <ExamplesNavbar />
        {/* <LandingPageHeader  datakonten = {this.state.aktifKonten}/> */}

        <ProfilePageHeader />
        
        <div className="section2 text-center" style={{ backgroundColor: "#E6E6E6" }}>
          <Container>
            <div>
              <h2 className="headtitle">Mengapa <span className="headtitle-red"> NIEC English course ?</span></h2>
            </div>
            <Row>
              <Col md="3" sm="12" className="mt-5 mb-5 box">
                <img style={{ maxWidth: '120px', maxHeight: '170px' }}
                  alt="..."
                  src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-01.png")}
                />
                <h4 className="iconp">Pathway University</h4>
                <p className="text-center" style={{ fontWeight: '500' }}>
                  Dapatkan informasi seputar kesempatan melanjutkan studi jenjang diploma, S1, S2 dan S3 di Luar Negeri.
                </p>
              </Col>
              <Col md="3" sm="12" className="mt-5 mb-5 box">
                <img style={{ maxWidth: '120px', maxHeight: '170px' }}
                  alt="..."
                  src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-02.png")}
                />
                <h4 className="iconp">Peluang Beasiswa</h4>
                <p className="text-center" style={{ fontWeight: '500' }}>
                  Test IELTS / TOEFL merupakan persyaratan utama untuk mendapatkan kesempatan meraih Beasiswa di Luar Negri</p>
              </Col>
              <Col md="3" sm="12" className="mt-5 mb-5 box">
                <img style={{ maxWidth: '120px', maxHeight: '170px' }}
                  alt="..."
                  src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-03.png")}
                />
                <h4 className="iconp">Bisa menghemat Kuliah ke Luar Negeri</h4>
                <p className="text-center" style={{ fontWeight: '500' }}>
                  Untuk beberapa kampus yang memberlakukan potongan harga sesuai dengan nilai sertifikat Bahasa Inggris </p>
              </Col>
              <Col md="3" sm="12" className="mt-5 mb-5 box">
                <img style={{ maxWidth: '120px', maxHeight: '170px' }}
                  alt="..."
                  src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-04.png")}
                />
                <h4 className="iconp">Memberi peluang mencari pekerjaan</h4>
                <p className="text-center" style={{ fontWeight: '500' }}>
                  NIEC English Course menjadi bekal anda dalam melamar pekerjaan baik di dalam negeri maupun di luar negeri</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section" style={{ backgroundColor: '' }}>
          <Container>
            <div>
              <h2 className="headtitle mb-5">Profile <span className="headtitle-red">Guru</span></h2>
            </div>
          </Container>
          <LandingPageHeader />
        </div>

        <div className="section2 text-center" style={{ backgroundColor: "#E6E6E6" }}>
          <Container>
            <div>
              <h2 className="headtitle">Placement Test <span className="headtitle-red">Partner</span></h2>
            </div>
            <Row className='justify-content-center'>
              <Col className="box" style={{ margin: '30px' }} lg='4'>
                <a href='https://canada-english.com/en/leveltest-niec' target='_blank'>
                  <img alt='...' style={{ width: '100%' }} className='img' src={require("../../../assets/img/partner/canadian-college.png")} />
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section" style={{ backgroundColor: '' }}>
          <Container>
            <Row>
              <Col lg="12" md="12">
                <Register test="bikin apa" />
              </Col>
            </Row>
          </Container>
        </div>

        <DemoFooter />
      </>
    );
  }
}

export default LandingPage;
