import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';

class Footer extends React.Component {

    state = {
        totalYears: Moment().format('YYYY')
    }

    render() {
        var footerstyle = {padding:"-20px 0",paddingBottom:'-20px'};  
        
        return (
            <footer id="footer" className="footer1" style={footerstyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 margin-t-15">
                            <h2 style={{ fontWeight:"bolder", color:"white" }}>Learn more than just a language</h2>
                            <br/>
                            <p style={{ fontWeight:"bolder", color:"white" }}>Improve your chance to pursue your dreams overseas</p>
                        </div>
                        <div className="col-lg-3 margin-t-15">
                            <h4 style={{ fontWeight:"bolder", color:"white" }}>FOLLOW US ON</h4>
                            <ul className="list-inline social margin-t-10">
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/Naresy-International-Education-Consultant-578610318865001/" target="__blank" className="social-icon"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://twitter.com/NIEC_Indonesia" target="__blank" className="social-icon"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.instagram.com/niec_indonesia/" target="__blank" className="social-icon"><i className="fa fa-instagram"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.youtube.com/channel/UCA5qiOgJdfMvADpyU7Oz7fQ/" target="__blank" className="social-icon"><i className="fa fa-youtube"></i></a>
                                </li>
                            </ul>
                            <div className="container footer-bottom text-white" style={{ paddingTop:'0px', paddingBottom:"20px", fontWeight:"bolder" }}>
                                {this.state.totalYears} | <a style={{ fontWeight:"bolder" , color:"white"}} href="http://niecindonesia.com/">NIEC Indonesia</a>
                            </div>
                        </div>
                        <div className="col-lg-3 margin-t-15 contact-us">
                            <h4 style={{ fontWeight:"bolder", paddingBottom:'10px', color:"white" }}>CONTACT US</h4>
                            <ul>
                                <li>
                                    <a href="tel:081238431234"  style={{ fontWeight:"bolder", color:"white" }}>0812 3843 1234</a><br/>
                                </li>
                                <li>
                                    <a href="mailto:info@niecindonesia.com" style={{ fontWeight:"bolder", color:"white" }}> info@niecindonesia.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;