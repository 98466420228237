/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import axios from 'axios';
import Cookies from 'universal-cookie';
//api url
import API_URL from '../../../ApiUrl';
// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

//function SectionLogin() {
const cookies = new Cookies();
class Login extends Component {

  state = {
    username:"",
    password:"",
    error:{},
    inputBorder: "border: 1px solid #ca0b0b!important",
  }
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    
    //username
    if(!this.state.username){
      formIsValid = false;
      errors.username = "Username wajib diisi";
      this.inputUsername.style = this.state.inputBorder;
    }
    // //email
    // if(!this.state.email){
    //     formIsValid = false;
    //     errors.email = "Email wajib diisi";
    //     this.inputEmail.style = this.state.inputBorder;
    // }
    // if(typeof this.state.email !== "undefined"){
    //     let lastAtPos  = this.state.email.lastIndexOf('@');
    //     let lastDotPos = this.state.email.lastIndexOf('.');
    //     if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
    //         formIsValid = false;
    //         errors.email = "Email tidak valid";  
    //         this.inputEmail.style = this.state.inputBorder;  
    //     }
    // }
    
    //password
    if(!this.state.password){
      formIsValid = false;
      errors.password = "Password wajib diisi";
      this.inputPassword.style = this.state.inputBorder;
    }
    this.setState({error:errors});
    return formIsValid;
  }

  loginSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
      this.sendData();
    }
  }

  sendData(){
    var base64 = require('base-64');//untuk enkripsi kode_member
    var formData = new FormData();
    formData.append('username',this.state.username);
    formData.append('password',this.state.password);
    let acak = this.generateRandomToken();
    axios({
      method:'POST',
      url:API_URL.url+"/admin/login",
      data:formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
       console.log('cek_id',reply.data[0].id_rep);
      // cookies.set('token',acak,{path:'/'})
      if(reply.data === 'ok'){
        cookies.set('token', base64.encode(reply.data[0].id_rep), {path:'/'});
          window.location.href="/home";
      }else{
        alert(reply.data);
      }
        
    }).catch(function(reply){
      console.log(reply);
    })
  }

  generateRandomToken(){
    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);
    return rand;
  }

  onBlurUsername(e){
    e.preventDefault()
    if(this.state.username.length > 5){
        this.inputUsername.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.username = '';                                 
            return { error };                   
        })
    }
  }

  onBlurPassword(e){
    e.preventDefault()
    if(this.state.password.length > 0){
        this.inputPassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.password = '';                                 
            return { error };                   
        })
    }
  }

  render() {
    
  return (
    <>
      {/* <div
        className="section section-image section-login"
        style={{
          backgroundImage: "url(" + require("./../../../assets/img/login-image.jpg") + ")"
        }}
      > */}

      <div
        style={{
          backgroundColor: "white"
        }}
        className="page-header"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col className="mx-auto" lg="4" md="6">
              <Card className="card-register">
                <h3 className="title mx-auto text-center"> Admin <br />  Login </h3>
                <Form className="register-form">
                  <label>Username</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input className="form-control" placeholder="Username" type="username" 
                      onChange={(e) => this.setState({username:e.target.value})}
                      onBlur={(e) => this.onBlurUsername(e)}
                      ref={e => this.inputUsername = e}
                    />
                  </InputGroup>
                  <label className="error-label-register" style={{marginTop: '5px'}}>
                      {this.state.error.email}
                  </label><br/>
                  <label style={{marginTop:'15px'}}>Password</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input className="form-control" placeholder="Password" type="password"
                      onChange={(e) => this.setState({password:e.target.value})}
                      onBlur={(e) => this.onBlurPassword(e)}
                      ref={e => this.inputPassword = e}
                    />
                  </InputGroup>
                  <label className="error-label-register"  style={{marginTop: '5px'}}>
                        {this.state.error.password}
                  </label>
                  <Button
                    block
                    className="btn-round"
                    //color="danger"
                    type="button"
                    onClick={e=>this.loginSubmit(e)}
                  >
                    Login
                  </Button>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
      }
}

export default Login;
