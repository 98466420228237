import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import img1 from '../../../assets/img/banner_1.png';
import img2 from '../../../assets/img/banner_2.png';

class Banner extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            height:window.innerHeight
        }
    }
    
    render() {
        return (
            <section id="home" className='bg-header-banner'>
              <Row>
                <Col lg='5'>
                  <img className='img-fluid-logo-banner' src={img1} />
                </Col>
                <Col lg='7'>
                  <img className='img-fluid-logo-banner' src={img2} />
                </Col>
              </Row>
            </section>
        );
    }
}

export default Banner;