import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

// Styles
import './assets/css/bootstrap.min.css';
import './assets/scss/paper-kit.scss';
import './assets/demo/demo.css';

import { Homepage, LandingPage, Home, Login, Logout } from "./containers/pages"
import { Register} from "./components/molecules"

class App extends Component {
  render() { 
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/home" component={Home} />
          <Route path="/admin" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path='/r/' component={() => { 
          window.location.href = '#register'; 
          return null;
        }}/>
          {/* <Redirect to="/index" /> */}
        </Switch>
      </Router>
    );
      }
}

export default App;
