import React, { Component } from "react";
import { Row, Col, Container } from 'reactstrap';
import './style.css';
import { LandingPageHeader, ExamplesNavbar, Register, DemoFooter } from './../../../components/molecules/';
import Carousel from 'react-bootstrap/Carousel';

//img
import header_img_01 from '../../../assets/img/new/header-image-01.png';
import pak_chandra_1 from '../../../assets/img/new/pak-chandra-1.png';
import icon_type from '../../../assets/img/new/group-342.png';

class Homepage extends Component {
    state = {
        
    }

    render() {
        return (
            <>
                <ExamplesNavbar />

                <div className="section">
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='6' md='6' sm='12' xs='12' className='mt-5 mb-5'>
                                <div className="text-wrapper-7 mb-5">NIEC English Course</div>
                                <div>
                                    <p className="kelas-persiapan mb-5">Kelas Persiapan Bahasa Kuliah ke Luar Negeri</p>
                                    <p className="mulailah-perjalanan mb-5">Mulailah perjalanan anda dalam menguasai Bahasa Inggris gratis sekarang juga!</p>
                                </div>
                                <Row>
                                    <Col lg='5' md='5' sm='12' xs='12' className='mb-3'>
                                        <div className='button-header'>
                                            <a href="#register"><div className="text-wrapper-3">Try Free Lessons</div></a>
                                        </div>
                                    </Col>
                                    <Col lg='5' md='5' sm='12' xs='12'>
                                        <div className='button-header'>
                                            <a href="https://placement-test.niecindonesia.com" target="_blank"><div className="text-wrapper-3">Placement Test</div></a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <img src={header_img_01} className='img-fluid mt-5 mb-5' />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="section">
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <div className="text-wrapper-7 mb-5">Mengapa NIEC English Course?</div>
                                <p className="kelas-persiapan mb-5">What We Offer</p>
                                <div className="text-wrapper-7 mb-5">What’s so special?</div>
                            </Col>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <Carousel className="text-center">
                                    <Carousel.Item>
                                        <p className="kelas-persiapan">Pathway University</p>
                                        <p className="p">
                                            Dapatkan informasi seputar kesempatan melanjutkan studi jenjang
                                            <br />
                                            diploma, S1, S2 dan S3 di Luar Negeri.
                                        </p>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <p className="kelas-persiapan">Peluang Beasiswa</p>
                                        <p className="text-wrapper-12">
                                            Test IELTS / TOEFL merupakan persyaratan utama untuk mendapatkan kesempatan meraih Beasiswa di Luar
                                            Negri
                                        </p>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <p className="kelas-persiapan">Bisa menghemat Kuliah ke Luar Negeri</p>
                                        <p className="p">
                                            Untuk beberapa kampus yang memberlakukan potongan harga sesuai dengan nilai sertifikat Bahasa
                                            Inggris
                                        </p>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <p className="kelas-persiapan">Memberi peluang mencari pekerjaan</p>
                                        <p className="p">
                                            NIEC English Course menjadi bekal anda dalam melamar pekerjaan baik di dalam negeri maupun di luar
                                            negeri
                                        </p>
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div id='program'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='7'>
                                <p className="kelas-persiapan mb-5" style={{textAlign: 'center'}}>Our English Course Programs</p>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col lg='6'>
                                <p className="mulailah-perjalanan" style={{textAlign: 'center'}}>Mulailah perjalanan anda dalam menguasai Bahasa Inggris gratis sekarang juga!</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='justify-content-center'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">Gratis Lah Pokoknya Ini</h3>
                                <p className="desk-1 text-left">
                                    Gratis nyobain english course tiga kali, trus ntar abis nyobain, kudu les beneran lah, tapi kalo
                                    gamau gapapa.   
                                </p>
                            </Col>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <div className="overlap-30">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Gratis!</div>
                                        <div className="text-wrapper-31 pb-3">3x Pertemuan</div>
                                        <div className="judul-basic text-left pl-5 mt-5">Rp. 0</div>
                                        <Row>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Min 5 students,</p>
                                                <p className="text-left font-bold">Max 10 students.</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">3x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <p className="judul-1 text-left">Mulailah perjalanan anda dalam menguasai Bahasa Inggris gratis sekarang juga!</p>
                                <div className='button-header mb-5 ml-5'>
                                    <a href="#register"><div className="text-wrapper-3">Try Free Lessons</div></a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='justify-content-center pt-5 pb-5'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">General English</h3>
                                <p className="desk-1 text-left">
                                    Designed for learners who want to improve their English skills in term of reading, listening, speaking, and writing, focusing in basic theories.
                                </p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 10</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.031.250</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">10x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-26">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 20</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 3.250.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">20x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-27">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group 10</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 1.406.250</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Min 5 students,</p>
                                                <p className="text-left font-bold">Max 10 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">10x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='justify-content-center pt-5 pb-5'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">IELTS Preparation</h3>
                                <p className="desk-1 text-left">
                                    Deciding to study abroad? Improve your IELTS skills as one of the major requirements to be accepted in overseas institutions.
                                </p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 10</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.250.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">10x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-26">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 20</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 3.600.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">20x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-28">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group 20</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.600.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Min 5 students,</p>
                                                <p className="text-left font-bold">Max 10 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">20x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* <div className="section p-5">
                    <div className="overlap-group-4">
                        <Row className='justify-content-center  pt-5 pb-5'>
                            <Col lg='3' md='3' sm='12' xs='12'>
                                <h3 className="judul-1 text-left mb-3">Specific Purpose</h3>
                                <p className="desk-1 text-left">
                                    Specifically designed for various professional settings to meet the corporate needs of English communication.
                                </p>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-25">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 10</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 2.031.250</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">10x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-26">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Private 20</div>
                                        <div className="text-wrapper-31 pb-3">20x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 3.250.000</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Max 1 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">20x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            <Col lg='2' md='2' sm='12' xs='12' className='p-1'>
                                <div className="overlap-27">
                                    <Container>
                                        <div className="text-wrapper-31 pt-3">Group 10</div>
                                        <div className="text-wrapper-31 pb-3">10x Pertemuan</div>
                                        <div className="judul-basic text-left pl-3 mt-5">Rp. 1.406.250</div>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">Min 5 students,</p>
                                                <p className="text-left font-bold">Max 10 students.</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg='3' md='3' sm='3' xs='3'>
                                                <img className="img-fluid ml-4" alt="Group" src={icon_type} />
                                            </Col>
                                            <Col lg='9' md='9' sm='9' xs='9'>
                                                <p className="text-left font-bold">10x Pertemuan</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div> */}

                <div className="section" id="teacher">
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='4' md='4' sm='12' xs='12' className='mb-5'>
                                <p className="kelas-persiapan mt-5">Meet our Teachers</p>
                                <div className="mulailah-perjalanan mt-5">Introducing NIEC English Course teachers who will help you to boost your English skills.</div>
                            </Col>
                            <Col lg='8' md='8' sm='12' xs='12'>
                                <LandingPageHeader/>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="section" id="placement">
                    <Container>
                        <div className="group-wrapper">
                            <Row className='justify-content-center align-content-center'>
                                <Col lg='7' md='7' sm='12' xs='12'>
                                    <div className="track-any-hashtag-s-2 mt-5 ml-5">Placement Test Partner</div>
                                    <p className="don-t-waste-time-on-3 mt-4 ml-5">Find out your English skill</p>
                                </Col>
                                <Col lg='4' md='4' sm='12' xs='12'>
                                    <div className="take-your-placement">
                                        <a href='https://canada-english.com/en/leveltest-niec' target="_blank" className="text-wrapper-4 text-center mt-3">Take your placement test</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <div className="section">
                    <Container>
                        <Row className='justify-content-center'>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <p className="kelas-persiapan mt-5">NIEC English Course is about mastering a timeless skill</p>
                                <div className="mulailah-perjalanan mt-5">Experience a journey of a comprehensive English learning</div>
                            </Col>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="section">
                    <Container>
                        <Register />
                    </Container>
                </div>

                <DemoFooter />
            </>
        )
    }
}

export default Homepage;