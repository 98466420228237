import React, { Component } from "react";
import axios from 'axios';
import API_URL from  './../../../ApiUrl';
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Swal from 'sweetalert2'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-multi-date-picker";
import Moment from 'moment';

class Register extends Component {
  state = {
    first_name:"",
    last_name:"",
    no_telp:"",
    email:"",
    alamat:"",
    id_program:"",
    id_teacher:"",
    id_type:"",
    id_jadwal:"",
    label_harga:"",
    code_jenjang:"",
    code_kelas:"",
    sekolah_kampus:"",
    ket_lainnya:"",
    kota_asal:"",
    kode_bisdev:"",
    kode_promo: "",
    ket_promo: "",
    message:"Error !",
    error: {},
    showSwal: false,
    formAlert:{display:'none'},
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    dataBizDev: [],
    dataKabupaten: [],
    dataProgram: [],
    dataGuru: [],
    dataGuruFilter:[],
    dataType: [],
    dataType:[],
    dataSchedule:[],
    selectedSchedule:"",
    aktifKonten:[],
    area_all:[],
    checkemail:"",
    pesanEmailError:"",
    hargamidtrans:"",
    hargalama: "",
    status_diskon: "",
    diskon:"",
    date: "",
    monday: Moment().startOf('month').day("Monday"),
    status_try: 1,
    referal_code: '',
  }

  componentDidMount() {
    // this.getKontenAktif();
    this.getBizdev();
    this.getArea();
  }

  getKontenAktif() {
    const url = API_URL.url+'/konten/aktifkonten';
    axios.get(url,{
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    })
    .then(response => response.data)
    .then((data) => {
      this.setState({ aktifKonten: data[0] })
    })
  }

  getBizdev(){
    const url = API_URL.superStudent+'/ambassador/consultant/jobdesk';
    let formData = new FormData ()
    formData.append('jobdesk', JSON.stringify(['JD004','JD017']))
    axios.post(url, formData, {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.ambassadorKey}`
        }
    })
    .then(response => response.data.data)
    .then(result => this.setState({ dataBizDev: result }))
    .catch(error => console.log(error))
  }

  getArea() {
    const url = API_URL.superStudentApplication+'/master/area';
    axios.get(url,{
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ area_all: data })
    })
  }

  getDistinctSchedule() {
    var formData = new FormData()
    formData.append('id_area', this.state.kota_asal);

    const url = API_URL.superStudentApplication+'/english-course/schedule-by-area';
    axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataSchedule: data })
    })
  }

  getProgramByDetail() {
    var formData = new FormData()
    formData.append('id_area', this.state.kota_asal);
    formData.append('schedule', this.state.selectedSchedule);

    const url = API_URL.superStudentApplication+'/english-course/program-by-detail';
    axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataProgram: data })
    })
  }

  getDataGuru() {
    var formData = new FormData()
    formData.append('id_area', this.state.kota_asal);
    formData.append('schedule', this.state.selectedSchedule);
    formData.append('id_program', this.state.id_program);

    const url = API_URL.superStudentApplication+'/english-course/teacher-by-detail';
    axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataGuru: data })
    })
  }

  getDataType() {
    var formData = new FormData();
    formData.append('id_area', this.state.kota_asal);
    formData.append('schedule', this.state.selectedSchedule);
    formData.append('id_program', this.state.id_program);

    const url = API_URL.superStudentApplication+'/english-course/type-by-detail';
    axios.post(url, formData, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataType: data })
    })
  }

  getHargaByType() {
    var formData = new FormData();
    formData.append('id_program',this.state.id_program);
    formData.append('id_type',this.state.id_type);
    formData.append('id_area',this.state.kota_asal);
    formData.append('schedule',this.state.selectedSchedule);
    formData.append('non_member','N');

    const url = API_URL.superStudentApplication+'/english-course/detail-payment';
    axios.post(url, formData, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      console.log(data)
      this.setState({
        label_harga: data.price,
        hargalama: data.price,
        hargamidtrans: data.fix_price
      })
    })
    .catch(e => console.log(e))
  }

  onBlurFirstName(e){
    e.preventDefault()
    if(this.state.first_name.length > 2){
      this.inputFirstName.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);  
        error.first_name = '';                                 
        return { error };                   
      })
    }
  }

  onBlurLastName(e){
    e.preventDefault()
    if(this.state.last_name.length > 2){
        this.inputLastName.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.last_name = '';                                 
            return { error };                   
        })
    }
  }

  onBlurAlamat(e){
    e.preventDefault()
    if(this.state.alamat.length > 2){
        this.inputAlamat.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.alamat = '';                                 
            return { error };                   
        })
    }
  }

  onBlurNoTelp(e){
      e.preventDefault()
      if(this.state.no_telp.length > 0){
          this.inputNoTelp.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.no_telp = '';                                 
              return { error };                   
          })
      }else{
        this.inputNoTelp.style = this.state.inputBorder;
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.error);  
            errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
            this.setState({error:errors});                  
        })
      }
  }

  onBlurSchedule(e){
    e.preventDefault()
    if(this.state.selectedSchedule.length > 2){
        this.inputSchedule.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.selectedSchedule = '';                                 
            return { error };                   
        })
    }
  }

  onBlurEmail(e){
    e.preventDefault()
    this.inputEmail.style = "";
    this.setState(prevState => {
      let error = Object.assign({}, prevState.error);  
      error.email = '';                                 
      return { error };                   
    })
  }

  onBlurProgram(e){
    e.preventDefault()
    if(this.state.id_program .length > 2){
        this.inputProgram.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_program  = '';                                 
            return { error };                   
        })
    }
  }

  onBlurGuru(e){
    e.preventDefault()
    if(this.state.id_teacher.length > 2){
        this.inputGuru.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_teacher = '';                                 
            return { error };                   
        })
    }
  }

  onBlurType(e){
    e.preventDefault()
    if(this.state.id_type.length > 2){
      this.inputType.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);  
        error.id_type = '';                                 
        return { error };                   
      })
    }
  }

  onBlurJadwal(e){
    e.preventDefault()
    if(this.state.id_jadwal.length > 2){
      this.inputType.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);  
        error.id_jadwal = '';                                 
        return { error };                   
      })
    }
  }

  onBlurLabelHarga(e){
    e.preventDefault()
    if(this.state.label_harga.length > 2){
      this.inputLabelHarga.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);  
        error.label_harga = '';                                 
        return { error };                   
      })
    }
  }

  setStatusPaid(e) {
    this.setState({
      status_try: e.target.value,
    })
  }

  setKabupaten(e) {
    this.setState({ 
      kota_asal: e.target.value,
      id_program: '',
      dataGuru: [],
      dataType: [],
      dataProgram: [],
      selectedSchedule: '',
      id_teacher: '',
      id_type: '',
      hargamidtrans: '',
      hargalama: ''
    }, () => {
      this.getDistinctSchedule();
    })
  }

  setSchedulle(e) {
    this.setState({
      selectedSchedule:e.target.value
    }, () => {
      this.getProgramByDetail();
    })
  }

  setProgram(e) {
    this.setState({
      id_program: e.target.value,
      id_teacher: "",
      dataGuru: [],
      id_type: "",
      dataType: [],
      status_payment: "N",
      hargamidtrans: ""
    }, () => {
      this.getDataType();
    })
  }

  setGuru(e){
    this.setState({
      id_teacher:e.target.value,
      id_type: "",
      dataType: [],
      status_payment: "N",
      hargamidtrans: ""
    }, () => {
      this.getDataType();
    })
  }

  setType(e){
    this.setState({
      id_type:e.target.value,
      label_harga: '',
      hargamidtrans: '',
      status_diskon: 'N',
    }, () => {
      this.getHargaByType()
    })
  }

  setJadwal(e) {
    this.setState({
      id_jadwal:e.target.value
    })
  }

  setTanggal(e) {
    this.setState({
      date:e.format('YYYY-MM-DD')
    })
  }

  setEmail = (e) => {
    e.target.value != '' ?
    this.setState({
      email: e.target.value,
    }, () => {
      axios({
        method: 'get',
        url: API_URL.superStudentApplication+'/members/check-member/'+this.state.email,
        headers: {
          'Accept': 'application/json'
        }
      })
      .then(response => response.data.data)
      .then(result => {
        var formData = new FormData();
        formData.append('id_teacher',this.state.id_teacher);
        formData.append('id_program',this.state.id_program);
        formData.append('id_type',this.state.id_type);
        formData.append('id_area',this.state.kota_asal);
        formData.append('schedule',this.state.selectedSchedule);

        if (result.status_payment == 'N')
          formData.append('non_member', 'none');

        if (result.student.ambassador != null)
          formData.append('ambassador', 'none');

        const url = API_URL.superStudentApplication+'/english-course/detail-payment';
        axios.post(url, formData, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.isMakassarKey}`
          }
        })
        .then(response => response.data.data)
        .then((data) => {
          if (result.status_payment == 'N') {
            this.setState({
              status_diskon: 'N',
              hargamidtrans: data.fix_price
            })
          } else { 
            this.setState({
              status_diskon: 'Y',
              ket_promo: "Member",
              hargamidtrans: data.fix_price
            })
          }
        })
      })
      .catch(error => console.log(error))
    })
    :
    this.setState({
      status_diskon: 'N',
    }, () => {
      this.getHargaByType();
    })
  }

  setCodeVoucher = (e) => {
    e.target.value != '' ?
    this.setState({
      kode_promo: e.target.value,
    }, () => {
      var formData = new FormData();
      formData.append('id_program',this.state.id_program);
      formData.append('id_type',this.state.id_type);
      formData.append('id_area',this.state.kota_asal);
      formData.append('schedule',this.state.selectedSchedule);
      formData.append('non_member', 'none');
      formData.append('code_voucher', this.state.kode_promo);

      const url = API_URL.superStudentApplication+'/english-course/detail-payment';
      axios.post(url, formData, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${API_URL.isMakassarKey}`
        }
      })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({
          status_diskon: 'Y',
          ket_promo: "Voucher",
          hargamidtrans: data.fix_price
        })
      })
    })
    :
    this.setState({
      status_diskon: 'N',
    }, () => {
      this.getHargaByType();
    })
  }

  handleValidation(){
    let errors = {};
    let formIsValid = true;
    //first_name
    if(!this.state.first_name){
      formIsValid = false;
      errors.first_name = "Nama depan wajib diisi";
      this.inputFirstName.style = this.state.inputBorder;
    }
    //last_name
     if(!this.state.last_name){
      formIsValid = false;
      errors.last_name = "Nama belakang wajib diisi";
      this.inputLastName.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
      formIsValid = false;
      errors.no_telp = "No telepon wajib diisi";
      this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
      formIsValid = false;
      errors.email = "Email wajib diisi";
      this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
      let lastAtPos  = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');
      if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
        formIsValid = false;
        errors.email = "Email tidak valid";  
        this.inputEmail.style = this.state.inputBorder;  
      }
    }
    if(this.state.checkemail === "Tolak"){
      formIsValid = false;
      errors.email = this.state.pesanEmailError;
      this.inputEmail.style = this.state.inputBorder;
    }
    //alamat
    if(!this.state.alamat){
      formIsValid = false;
      errors.alamat = "Alamat wajib diisi";
      this.inputAlamat.style = this.state.inputBorder;
    }
    //Program
    if(!this.state.id_program ){
      formIsValid = false;
      errors.id_program  = "Program wajib diisi";
      this.inputProgram.style = this.state.inputBorder;
    }
    //schedule
    if(!this.state.selectedSchedule ){
      formIsValid = false;
      errors.selectedSchedule  = "Schedule wajib diisi";
      this.inputSchedule.style = this.state.inputBorder;
    }
    //Type
    if(!this.state.id_type ){
      formIsValid = false;
      errors.id_type  = "Tipe wajib diisi";
      this.inputType.style = this.state.inputBorder;
    }
    this.setState({error:errors});
    return formIsValid;
  }

  handleValidationTry(){
    let errors = {};
    let formIsValid = true;
    //first_name
    if(!this.state.first_name){
      formIsValid = false;
      errors.first_name = "Nama depan wajib diisi";
      this.inputFirstName.style = this.state.inputBorder;
    }
    //last_name
     if(!this.state.last_name){
      formIsValid = false;
      errors.last_name = "Nama belakang wajib diisi";
      this.inputLastName.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
      formIsValid = false;
      errors.no_telp = "No telepon wajib diisi";
      this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
      formIsValid = false;
      errors.email = "Email wajib diisi";
      this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
      let lastAtPos  = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');
      if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
        formIsValid = false;
        errors.email = "Email tidak valid";  
        this.inputEmail.style = this.state.inputBorder;  
      }
    }
    if(this.state.checkemail === "Tolak"){
      formIsValid = false;
      errors.email = this.state.pesanEmailError;
      this.inputEmail.style = this.state.inputBorder;
    }
    //alamat
    if(!this.state.alamat){
      formIsValid = false;
      errors.alamat = "Alamat wajib diisi";
      this.inputAlamat.style = this.state.inputBorder;
    }
    this.setState({error:errors});
    return formIsValid;
  }

  registrationSubmit() {
    if (this.state.status_try == 1) {
      if(this.handleValidationTry()){
        this.sendData();
      } else {
        this.setState({
          btnDisabled: true
        })
      }
    } else {
      if(this.handleValidation()){
        this.sendData();
      } else {
        this.setState({
          btnDisabled: true
        })
      } 
    }
  }

  sendData(){
    Swal.fire({
      title: 'Register Dalam Proses, Mohon Tunggu',
      showConfirmButton: false,
      timer: 70000,
      didOpen: () => {
        Swal.showLoading()
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })

    this.setState({
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('first_name',this.state.first_name);
    formData.append('last_name',this.state.last_name);
    formData.append('no_wa',this.state.no_telp);
    formData.append('email',this.state.email);
    formData.append('alamat',this.state.alamat);
    formData.append('id_program',this.state.id_program );
    formData.append('id_teacher',this.state.id_teacher);
    formData.append('selectedSchedule',this.state.selectedSchedule);
    formData.append('id_type',this.state.id_type);
    formData.append('harga',this.state.hargamidtrans);
    formData.append('id_kabkota',this.state.kota_asal);
    formData.append('recomender',this.state.kode_bisdev);
    formData.append('date', this.state.date);
    formData.append('referal_code', this.state.referal_code);

    var url = "/checkout-english-trial";

    if (this.state.status_try != 1) {
      url = "/checkout-english";
    }
   
    axios({
      method: 'post',
      url:API_URL.superStudentApplication+url,
      data:formData,
      headers: {
        'Accept': 'application/json'
      }
    }).then((reply)=>{
      if (this.state.status_try == 1) {
        if(reply.status === 200){
          Swal.fire({
            icon: 'success',
            title: 'Your data has been registered',
            showConfirmButton: false,
            timer: 1500
          })
          window.location.reload()
        } else {
          this.setState({btnDisabled:true})
        }
      } else {
        if(reply.status === 200){
          window.snap.pay(reply.data, {
            onSuccess: function(result){window.location.reload(false);},
            onPending: function(result){window.location.reload(false);},
            onError: function(result){console.log('error');console.log(result);},            
            onClose: function(){console.log('customer closed the popup without finishing the payment');}
          })
        } else {
          this.setState({btnDisabled:true})
        }
      }
    }).catch(function(reply){
      console.log(reply)
    });
  }

  render() {
    const totalHarga = this.state.hargamidtrans;
    const totalDiskon = this.state.hargalama;

    let rupiah = Intl.NumberFormat('id-ID');
    
    return (
      <>
        <div className="main" id='register'>
          <div className="" style={{ backgroundColor: ''}}>
            <Container>
              <Row >
                <Col className="ml-auto mr-auto mb-5" lg="12" md="12" style={{backgroundColor:"", padding:"0px"}}>
                  <div style={{ backgroundColor: '', paddingTop:"0px"}}>
                    <form>
                      <Row style={{backgroundColor:"", margin:"0px"}}>
                        <Col className="ml-auto mr-auto" md="" style={{backgroundColor:"", margin:"0px"}}>
                          <Row>
                            <Col lg="6" md="6" style={{backgroundColor:"#ca2525", padding: '70px'}}>
                              <FormGroup style={{marginBottom:"0px"}}>
                                <Label style={{ color: 'white', fontWeight:'500'}}>Tipe Kelas</Label>
                                <select name="status_try" id="status_try" className="form-control" onChange={(e) => this.setStatusPaid(e)} ref={e => this.inputStatusPaid = e} value={this.state.status_try}>
                                  <option value=''>--Pilih Schedule--</option>
                                  <option value='1'>Try Free Lesson</option>
                                  <option value='2'>Normal Lesson</option>
                                </select>
                                <label className="error-label-register">
                                  {this.state.error.status_try}
                                </label>
                              </FormGroup>
                              {
                                this.state.status_try == 1 ?
                                <div>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="kota_asal" style={{ color: 'white', fontWeight:'500'}}>Kota Tempat Kursus</Label>
                                    <select name="kota_asal" id="kota_asal" className="form-control" value={this.state.kota_asal}
                                      onChange={(e) => this.setKabupaten(e)}
                                      ref={e => this.inputSchedule = e}
                                    >
                                      <option value=''>--Pilih Schedule--</option>
                                      {
                                        this.state.area_all.map((area) => (
                                          <option value={area.id_area}>{area.area}</option>
                                        ))
                                      }
                                    </select>
                                    <label className="error-label-register">
                                      {this.state.error.kota_asal}
                                    </label>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label style={{ color: 'white', fontWeight:'500'}}>Pilih Tanggal</Label>
                                    <br></br>
                                    <DatePicker
                                      format="DD/MM/YYYY"
                                      inputClass="form-control"
                                      minDate={Moment().format('YYYY/MM/DD')}
                                      mapDays={({ date }) => {
                                        let isWeekend = [0, 2, 3, 4, 5, 6].includes(date.weekDay.index)
                                        
                                        if (isWeekend) return {
                                          disabled: true,
                                          style: { color: "#ccc" }
                                        }
                                      }}
                                      style={{width: '100%'}}
                                      onChange={(e) => this.setTanggal(e)}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.tanggal}
                                    </label>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="id_type" style={{ color: 'white', fontWeight:'500'}}>Recomender</Label>
                                    <select className="form-control" name="kode_bisdev" type="number" id="kode_bisdev" onChange={(e) => this.setState({kode_bisdev:e.target.value})} value={this.state.kode_bisdev}>
                                      <option value=''>--Pilih Recomender--</option>
                                      {
                                          this.state.dataBizDev.map((data)=>(
                                              <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                                          ))
                                      }
                                    </select>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="first_name" style={{ color: 'white', fontWeight:'500'}}>First Name</Label>
                                    <input md="3" className="form-control" type="first_name"  id="first_name" placeholder=""
                                      onChange={(e) => this.setState({first_name:e.target.value})}
                                      onBlur={(e) => this.onBlurFirstName(e)}
                                      ref={e => this.inputFirstName = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.first_name}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="last_name" style={{ color: 'white', fontWeight:'500'}}>Last Name</Label>
                                    <input md="3" className="form-control" type="last_name"  id="last_name" placeholder=""
                                      onChange={(e) => this.setState({last_name:e.target.value})}
                                      onBlur={(e) => this.onBlurLastName(e)}
                                      ref={e => this.inputLastName = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.last_name}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="no_wa" style={{ color: 'white', fontWeight:'500'}}>Phone / Whatsapp</Label>
                                    <input className="form-control" type="number"  id="no_wa" placeholder=""
                                      onChange={(e) => this.setState({no_telp:e.target.value})}
                                      onBlur={(e) => this.onBlurNoTelp(e)}
                                      ref={e => this.inputNoTelp = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.no_telp}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="inputEmail4" style={{ color: 'white', fontWeight:'500'}}>Email</Label>
                                    <input className="form-control" type="email"  id="inputEmail4" placeholder=""
                                      onChange={(e) => this.setEmail(e)} 
                                      onBlur={(e) => this.onBlurEmail(e)}
                                      ref={e => this.inputEmail = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.email}
                                    </label>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="alamat"  className="pull-left" style={{ color: 'white', fontWeight:'500'}}>Detail Address</Label>
                                    <textarea className="form-control form-control-new" rows="3" id="alamat" placeholder=""
                                      onChange={(e) => this.setState({alamat:e.target.value})}
                                      onBlur={(e) => this.onBlurAlamat(e)}
                                      ref={e => this.inputAlamat = e}> 
                                    </textarea>
                                    <label className="error-label-register">
                                      {this.state.error.alamat}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="referal_code" style={{ color: 'white', fontWeight:'500'}}>Referal Code</Label>
                                    <input className="form-control" type="text"  id="referal_code" placeholder=""
                                      onChange={(e) => this.setState({referal_code:e.target.value})}
                                      ref={e => this.inputReferalCode = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.referal_code}
                                    </label>
                                  </FormGroup>
                                </div>
                                :
                                <div>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="kota_asal" style={{ color: 'white', fontWeight:'500'}}>Kota Tempat Kursus</Label>
                                    <select name="kota_asal" id="kota_asal" className="form-control" value={this.state.kota_asal}
                                      onChange={(e) => this.setKabupaten(e)}
                                      ref={e => this.inputSchedule = e}
                                    >
                                      <option value=''>--Pilih Schedule--</option>
                                      {
                                        this.state.area_all.map((area) => (
                                          <option value={area.id_area}>{area.area}</option>
                                        ))
                                      }
                                    </select>
                                    <label className="error-label-register">
                                      {this.state.error.kota_asal}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="selectedSchedule" className="pull-left" style={{color:'white', fontWeight:'500'}}>Pilih Schedule</Label>
                                    <select name="selectedSchedule" id="selectedSchedule" className="form-control" value={this.state.selectedSchedule}
                                      onChange={(e) => this.setSchedulle(e)}
                                      onBlur={(e) => this.onBlurSchedule(e)}
                                      ref={e => this.inputSchedule = e}
                                    >
                                      <option value="">--Pilih Tipe--</option>
                                      {
                                        this.state.dataSchedule.map((schedule) => (
                                          <option value={schedule.schedule}>{schedule.schedule}</option>
                                        ))
                                      }
                                    </select>
                                    <label className="error-label-register">
                                        {this.state.error.selectedSchedule}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="id_program" style={{ color: 'white', fontWeight:'500'}}>Program</Label>
                                    <select md="3" className="form-control" type="id_program"  id="id_program" placeholder=""
                                      onChange={(e) => this.setProgram(e)}
                                      onBlur={(e) => this.onBlurProgram(e)}
                                      ref={e => this.inputProgram = e}
                                    >
                                      <option>--Pilih Program--</option>
                                    
                                      {this.state.dataProgram.map((data1)=>(
                                        <option key={data1.id_program} value={data1.id_program}>{data1.program.nama_program}</option>
                                      ))} 
                                    </select>
                                    <label className="error-label-register">
                                        {this.state.error.id_program}
                                    </label>
                                  </FormGroup>
                                  {/* <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="id_teacher" style={{ color: 'white', fontWeight:'500'}}>Teacher</Label>
                                    <select md="3" className="form-control" type="id_teacher"  id="id_teacher" placeholder="" value={this.state.id_teacher}
                                      onChange={(e) => this.setGuru(e)}
                                      onBlur={(e) => this.onBlurGuru(e)}
                                      ref={e => this.inputGuru = e}
                                    >
                                      <option value=''>--Pilih Guru--</option>
                                      {this.state.dataGuru.map((data2)=> (
                                        <option key={data2.id_teacher} value={data2.id_teacher}>{data2.nama_lengkap}</option>
                                      ))}
                                    </select>
                                    <label className="error-label-register">
                                      {this.state.error.id_teacher}
                                    </label>
                                  </FormGroup> */}
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="id_type" style={{ color: 'white', fontWeight:'500'}}>Pilih Tipe Kursus</Label>
                                    <select md="3" className="form-control" type="id_type"  id="id_type" placeholder="" value={this.state.id_type}
                                      onChange={(e) => this.setType(e)}
                                      onBlur={(e) => this.onBlurType(e)}
                                      ref={e => this.inputType = e}
                                    >
                                      <option value=''>--Pilih Tipe Kursus--</option>
                                      {this.state.dataType.map((data3)=>(
                                        <option key={data3.id_type} value={data3.id_type}>{data3.type.types} {data3.type.description}</option>
                                      ))}
                                    </select>
                                    <label className="error-label-register">
                                      {this.state.error.id_type}
                                    </label>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label style={{ color: 'white', fontWeight:'500'}}>Pilih Tanggal</Label>
                                    <br></br>
                                    <DatePicker
                                      format="DD/MM/YYYY"
                                      inputClass="form-control"
                                      minDate={Moment().format('YYYY/MM/DD')}
                                      mapDays={({ date }) => {
                                        let isWeekend = [0, 2, 3, 4, 5, 6].includes(date.weekDay.index)
                                        
                                        if (isWeekend) return {
                                          disabled: true,
                                          style: { color: "#ccc" }
                                        }
                                      }}
                                      style={{width: '100%'}}
                                      onChange={(e) => this.setTanggal(e)}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.tanggal}
                                    </label>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="id_type" style={{ color: 'white', fontWeight:'500'}}>Recomender</Label>
                                    <select className="form-control" name="kode_bisdev" type="number" id="kode_bisdev" onChange={(e) => this.setState({kode_bisdev:e.target.value})} value={this.state.kode_bisdev}>
                                      <option value=''>--Pilih Recomender--</option>
                                      {
                                          this.state.dataBizDev.map((data)=>(
                                              <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                                          ))
                                      }
                                    </select>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="first_name" style={{ color: 'white', fontWeight:'500'}}>First Name</Label>
                                    <input md="3" className="form-control" type="first_name"  id="first_name" placeholder=""
                                      onChange={(e) => this.setState({first_name:e.target.value})}
                                      onBlur={(e) => this.onBlurFirstName(e)}
                                      ref={e => this.inputFirstName = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.first_name}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="last_name" style={{ color: 'white', fontWeight:'500'}}>Last Name</Label>
                                    <input md="3" className="form-control" type="last_name"  id="last_name" placeholder=""
                                      onChange={(e) => this.setState({last_name:e.target.value})}
                                      onBlur={(e) => this.onBlurLastName(e)}
                                      ref={e => this.inputLastName = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.last_name}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="no_wa" style={{ color: 'white', fontWeight:'500'}}>Phone / Whatsapp</Label>
                                    <input className="form-control" type="number"  id="no_wa" placeholder=""
                                      onChange={(e) => this.setState({no_telp:e.target.value})}
                                      onBlur={(e) => this.onBlurNoTelp(e)}
                                      ref={e => this.inputNoTelp = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.no_telp}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="inputEmail4" style={{ color: 'white', fontWeight:'500'}}>Email</Label>
                                    <input className="form-control" type="email"  id="inputEmail4" placeholder=""
                                      onChange={(e) => this.setEmail(e)} 
                                      onBlur={(e) => this.onBlurEmail(e)}
                                      ref={e => this.inputEmail = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.email}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="kode_promo" style={{ color: 'white', fontWeight:'500'}}>Kode Promo</Label>
                                    <input className="form-control" type="text"  id="kode_promo" placeholder=""
                                      onChange={(e) => this.setCodeVoucher(e)}
                                      ref={e => this.inputKodePromo = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.kode_promo}
                                    </label>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="alamat"  className="pull-left" style={{ color: 'white', fontWeight:'500'}}>Detail Address</Label>
                                    <textarea className="form-control form-control-new" rows="3" id="alamat" placeholder=""
                                      onChange={(e) => this.setState({alamat:e.target.value})}
                                      onBlur={(e) => this.onBlurAlamat(e)}
                                      ref={e => this.inputAlamat = e}> 
                                    </textarea>
                                    <label className="error-label-register">
                                      {this.state.error.alamat}
                                    </label>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom:"0px"}}>
                                    <Label for="referal_code" style={{ color: 'white', fontWeight:'500'}}>Referal Code</Label>
                                    <input className="form-control" type="text"  id="referal_code" placeholder=""
                                      onChange={(e) => this.setState({referal_code:e.target.value})}
                                      ref={e => this.inputReferalCode = e}
                                    />
                                    <label className="error-label-register">
                                      {this.state.error.referal_code}
                                    </label>
                                  </FormGroup>
                                </div>
                              }
                            </Col>
                            <Col lg="6" md="6" style={{ padding: '70px'}}>
                              <h2 id="register" className="headtitle mb-5">Student Detail</h2>
                              <table className="table-new mb-5">
                                <tr>
                                  <td>Nama</td>
                                  <td>:</td>
                                  <td>{this.state.first_name} {this.state.last_name}</td>
                                </tr>
                                <tr>
                                  <td>No. Telp</td>
                                  <td>:</td>
                                  <td>{this.state.no_telp}</td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>:</td>
                                  <td>{this.state.email}</td>
                                </tr>
                                <tr>
                                  <td>Address</td>
                                  <td>:</td>
                                  <td>{this.state.alamat}</td>
                                </tr>
                              </table>
                              <div className="mulailah-perjalanan mb-5">Setelah melakukan pembayaran, harap cek email yang telah terdaftar untuk pengecekan invoice serta konfirmasi pembayaran.</div>
                              {
                                this.state.status_diskon == 'Y' ?
                                <h3 id="register" className="pull-right" style={{fontSize: '25px  !important', fontWeight: "bold"}}><b>Diskon {this.state.ket_promo} : Rp. <s>{rupiah.format(totalDiskon)}</s></b></h3>
                                :
                                ""
                              }
                              <h3 id="register" className="pull-right mb-5" style={{fontSize: '25px !important', fontWeight: "bold"}}>Harga : Rp. {rupiah.format(totalHarga)}</h3>
                              <button type="button" onClick={() => this.registrationSubmit()} disabled={this.state.btnDisabled} className='button-header pull-right' style={{ marginTop: '100px' }}>
                                <div className="text-wrapper-3">Check Out My Order</div>
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
