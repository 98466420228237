/** @format */
import _LandingPage from './LandingPage';
import _Home from './Home';
import _Login from './Login';
import _Logout from './Logout';
import _Homepage from './Homepage';

export const LandingPage = _LandingPage;
export const Home = _Home;
export const Login = _Login;
export const Logout = _Logout;
export const Homepage = _Homepage;
